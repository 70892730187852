const IconTrash = (props) => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 2H26.5M2.5 8H38.5M34.5 8L33.0974 29.0386C32.887 32.1951 32.7818 33.7733 32.1 34.97C31.4998 36.0236 30.5945 36.8706 29.5033 37.3994C28.2639 38 26.6822 38 23.5187 38H17.4813C14.3178 38 12.7361 38 11.4967 37.3994C10.4055 36.8706 9.50018 36.0236 8.89997 34.97C8.21822 33.7733 8.11301 32.1951 7.90257 29.0386L6.5 8M16.5 17V27M24.5 17V27"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconTrash
